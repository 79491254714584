export const useAsyncDataUtils = () => {
  /**
 * @function getLazyFieldMapper - Returns a mapper to obtain the lazy fields for a specific combination of types
 * @param {Object} field - Block field with type and subtype
 * @returns {Function} mapper - Mapper to apply to retrieve the lazy fields
 */
  const getLazyFieldMapper = ({ type, subType, imageOptions }) => {
    if (type === 'BlockGroup' && subType === 'BlockTabContentLazy') {
      return (e) => {
        return e?.items?.flatMap(i => i?.items?.map(subItem => ({ ...subItem, imageOptions })))
      }
    }
    if (type === 'BlockTerminalSpecsLazy') {
      return e => ({ id: e.sys.id, contentType: e.__typename })
    }

    if (type === 'BlockPartnerMetadataLazy') {
      return e => ({ id: e.sys.id, contentType: e.__typename })
    }
    return e => e
  }

  /**
   * @function replaceLazyFields - Replaces the lazy fields of a parameter for a specific combination of types
   * @param {Object} field - Block field with type and subtype
   * @param {Object} dataField - PageData field to be replaced
   * @param {Map} extraDataMap - Map containing the hydrated data that will replace
   * @returns {Object} dataField - PageData field with its contents replaced
   */
  const replaceLazyFields = (field, dataField, extraDataMap) => {
    // Case BlockGroup & TabContent
    if (field.type === 'BlockGroup' && field.subType === 'BlockTabContentLazy') {
      dataField.items = dataField.items.map(tab => ({
        ...tab,
        items: tab.items.map(tabContent =>
          extraDataMap.has(tabContent.id) ? extraDataMap.get(tabContent.id) : tabContent
        )
      }))

      return dataField
    }

    if (field.type === 'BlockTerminalSpecsLazy' || field.type === 'BlockPartnerMetadataLazy') {
      dataField = extraDataMap.get(dataField.sys?.id)
      return dataField
    }

    // Default
    return dataField
  }

  /**
   * Executes Promise.all but in batches
   * @param task {Function} - The task to run for each item
   * @param items {Array} - The items
   * @param batchSize {Number} - batch size
   * @param timeout {Number} - timeout in millis
   * @return {Promise<*[]>} - Returns a promise with the results
   */
  async function promiseAllInBatches (task, items, batchSize, timeout = 2000) {
    let position = 0
    let results = []
    while (position < items.length) {
      const itemsForBatch = items.slice(position, position + batchSize)
      const newResults = await Promise.all(itemsForBatch.map(item => task(item)))
      results = results.concat(newResults)
      position += batchSize
      await waitFor(timeout)
    }

    return results
  }

  /**
   * Adds a timeout of X milliseconds
   * @param time {Number} - The amount of milliseconds to wait
   * @return {Promise} - Returns a promise that waits x number of milliseconds
   */
  function waitFor (time) {
    return new Promise((resolve) => {
      setTimeout(() => { resolve() }, time)
    })
  }

  return {
    waitFor,
    promiseAllInBatches,
    replaceLazyFields,
    getLazyFieldMapper
  }
}
