import { useGlobalData } from './useGlobalData'
import { useI18nPageInfo } from './useI18nPageInfo'

export const usePageSEO = (data: Ref) => {
  const runtimeConfig = useRuntimeConfig()
  const siteUrl = useRuntimeConfig().public.siteUrl
  const { ogImage } = useGlobalData()
  const gtm = useGtm()

  const { isHome, fetchRoutes } = useRoutesUtils()
  const { i18nParams } = useI18nPageInfo()
  const { locale } = useI18n()
  const route = useRoute()
  const hasSlug = !!route.params.slug
  const preview = runtimeConfig.public.preview
  const pageData = computed(() => data?.value?.pageData || {})
  const titleBrand = computed(() => pageData.value.seo?.title ? ' - Adyen' : 'Adyen')

  if (hasSlug) {
    const setI18nParams = useSetI18nParams()
    setI18nParams(i18nParams.value)
  }

  const i18nHead = useLocaleHead({
    addSeoAttributes: true
  })

  // - Generate alternate link tags only for existing route locales
  const routeLocales = Object.keys(i18nParams.value || {})

  // console.log({ routeLocales })
  // console.log({ i18nHead: i18nHead.value })
  // Not needed after the i18n lib update
  // const routeLocalesIso = routeLocales.length ? routeLocales.map(locale => getLocaleIso(locale)) : locales.map(item => item.iso)

  // TODO: Test the solution for overriding x-default link when we have pages that don't have default locale (en) version

  const i18nHeadLinkFiltered = i18nHead.value?.link.filter((item) => {
    // keep default and canonical link
    if (item.rel === 'canonical' || item.hreflang === 'x-default') {
      return true
    }
    return routeLocales.includes(item.hreflang)
  }).map((item) => {
    // Override x-default link in case the page does not have default locale (en) version
    if (item.hreflang === 'x-default' && !routeLocales.includes('en')) {
      return { ...item, href: i18nHead.value?.link.find(item => item.rel === 'canonical')?.href }
    }
    return item
  })

  // - Remove section name from href of alternate links when target site is not the main website

  // This regex matches /{targetSite} when it is followed by either a slash (/) or the end of the string ($).
  //  const urlReplaceRegex = new RegExp(`/${targetSite}(\\/|$)`, 'g')
  //  const i18nHeadLinkForTargetSite = IS_DEFAULT_TARGET_SITE
  //    ? i18nHeadLinkFiltered
  //    : i18nHeadLinkFiltered.map(item => ({
  //      ...item,
  //      href: item.href.replace(urlReplaceRegex, '/')
  //    }))

  const i18nHeadLinkForTargetSite = i18nHeadLinkFiltered

  // Patch to add tailing slash to home pages alternate links, required by SEO for compatibility with old implementation
  const i18nHeadLinks = isHome(route.path)
    ? i18nHeadLinkForTargetSite
    : i18nHeadLinkForTargetSite.map(item => ({
      ...item,
      href: item.href.endsWith('/') ? item.href : `${item.href}/` // add tailing slash only if it is not present
    }))

  // - Generate  og:locale meta tags only for existing route locales
  const i18nHeadMetaFiltered = i18nHead?.value?.meta.filter(item => routeLocales.includes(item.content)) || []

  // [...(i18nHead.value.meta ||
  // useHead is a Nuxt 3 out-of-the-box composable

  useHead({
    title: `${pageData.value.seo?.title}${titleBrand.value}`,
    htmlAttrs: {
      lang: i18nHead?.value?.htmlAttrs.lang || 'en'
    },
    link: [...(i18nHeadLinks || [])],
    meta: [
      {
        hid: 'og:image',
        name: 'image',
        property: 'og:image',
        content: ogImage.value
      },
      {
        hid: 'description',
        name: 'description',
        content: pageData.value.seo?.description
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: pageData.value.seo?.keywords
      },
      {
        hid: 'robots',
        name: 'robots',
        content: pageData.value.seo?.robots
      },
      {
        hid: 'og:url',
        name: 'url',
        property: 'og:url',
        content: `${siteUrl}${route.path}`
      },
      {
        hid: 'og:title',
        name: 'title',
        property: 'og:title',
        content: pageData.value.seo?.title
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: pageData.value.seo?.description
      },
      // Overwrites default open graph image
      ...(pageData.value.seo?.ogImage
        ? [{
            hid: 'og:image',
            name: 'image',
            property: 'og:image',
            content: pageData.value.seo?.ogImage
          }]
        : []),
      ...i18nHeadMetaFiltered
    ] || []
  })

  // watch(() => route.path, (value) => {
  //   // TODO: findout how to achieve this
  //   // Trigger `page` load event when routes change
  //   // Taking into account only the path, not hash or query params changes
  //   // if (to?.path !== from?.path) {
  //   //   setTimeout(() => {
  //   //     this.trackPage()
  //   //   }, 500)
  //   // }

  //   trackPage()
  // }, { imnmediate: true })

  onMounted(() => {
    //   // 1.Trigger `page` load event when app loads for the first time
    trackPage()
  })

  // methods

  /**
     * trackPage,
     * handles all tracking events on page load
     */
  async function trackPage () {
    const routes = await fetchRoutes(runtimeConfig.public.targetSite)
    const { getDefaultLocaleRoute } = useRoutes(routes, locale)

    // Skip tracking if not running in the client

    const defaultLocaleRoute = getDefaultLocaleRoute(route?.path)

    // this.$segment?.page(
    //   defaultLocaleRoute?.payload?.category,
    //   defaultLocaleRoute?.route,
    //   { subcategory: defaultLocaleRoute?.payload?.subcategory }
    // )

    const [lang, region] = locale.value?.split('_') || []

    const eventData = {
      event: 'virtual_page',
      page: {
        group: defaultLocaleRoute?.payload?.category,
        type: defaultLocaleRoute?.payload?.subcategory,
        path: defaultLocaleRoute?.route,
        locale_path: route?.path,
        full_url: window?.location.href
      },
      site: {
        lang,
        region
      }
    }

    // TODO check
    gtm.push(eventData)
  }
}
