import { print } from 'graphql'
import { lowerFirst } from 'lodash-es'
import { getQueryForContentType } from '../data-layer/dynamic/queries'
import { getQueryForEntries } from '../data-layer/dynamic/query-entry-dynamic'

type PageDataOptions = {
  layerMappers: any;
  layerFragments: any;
}

export const usePageData = (targetSite: string, { layerMappers, layerFragments }: PageDataOptions) => {
  const runtimeConfig = useRuntimeConfig()
  const { queryContentful } = useQueries()
  const { getLocaleIso } = useLocales()
  const { getSlug, getSlugParent } = usePageUtils()
  const { getLazyFieldMapper, replaceLazyFields } = useAsyncDataUtils()
  const { locale: localeRef } = useI18n()
  const { initSEO } = useI18nPageInfo()
  const route = useRoute()
  const preview = runtimeConfig.public.preview
  const { dataDynamicPageMapper, dataPageMapper, dataDynamicEntryMapper, routes, retrieveRoutes } = useDataMapper()

  /**
   * @function useStandardAsyncData - Returns the standard data obtained from the query of a page
   * @param {Object} definition - Page definition
   * @param {Object} options - Options to modify the data { withGlobalContentType = false }
   * @param {String} pageSlug - Page slug
   * @returns {Promise} data - With the params pageData and globalContentType if applicable
   */

  const useStandardAsyncData = async (
    definition,
    options
  ) => {
    // needs to be sorted since we receive the definition like { pageCareersDei: {...x} }
    // const sortedDefinition = Object.values(definition)[0]
    // console.log({ sortedDefinition })

    const queryForContentTypeOptions = { withParent: false, layerFragments }
    let parentPageId = null
    if (options?.withParent && definition) {
      await retrieveRoutes()
      const { getRouteByContentType } = useRoutes(routes.value, localeRef)

      const slugParent = getSlugParent(route.path)
      const parentContentType = definition.fields.find(i => i.id === 'parent')?.type
      const lowercaseParentContentType = lowerFirst(parentContentType)
      parentPageId = getRouteByContentType(slugParent, lowercaseParentContentType)?.payload?.id
      queryForContentTypeOptions.withParent = true
      queryForContentTypeOptions.parentPageId = parentPageId
    }

    const query = getQueryForContentType(definition?.contentType, queryForContentTypeOptions)

    const data = await useAsyncDataWithQuery(
      query,
      {
        ...options,
        definition,
        dynamic: true
      }
    )

    return data
  }

  /**
   * @function useAsyncDataWithQuery - Returns the data obtained from the query of a page
   * @param {Object} query
   * @param {Object} options - Options to modify the data { withGlobalContentType = false }
   * @returns {Promise} data - With the params pageData and globalContentData if applicable
   */

  const useAsyncDataWithQuery = async (
    query: any,
    {
      pageSlug,
      dynamic = false,
      variables,
      definition
    }: { pageSlug?: string, dynamic?: boolean, variables?: any, definition?: any}
  ) => {
    const slug = pageSlug || getSlug(route.path)
    const locale = getLocaleIso(localeRef.value)
    const vars = variables || {}

    try {
      const data = await queryContentful({
        query,
        variables: {
          slug,
          locale,
          preview,
          ...vars
        }
      }, { log: false })

      if (!data?.page?.items?.[0]) {
        return { pageData: {} }
      }

      const entry = data.page?.items[0]

      // IMPORTANT We need to fetch the routes before mapping so the mapping is synchronous
      await retrieveRoutes()
      const pageData = dynamic ? dataDynamicPageMapper(entry, {}, layerMappers) : dataPageMapper(entry, layerMappers || {})

      // set route params
      const i18nRouteParams = await initSEO({ sysId: pageData.sysId, locale, targetSite })

      // This function mutates pageData object to fetch all the lazy fields and populate them
      // Only do it if definition is present (no legacy queries)
      if (pageData?.content && definition) {
        await fetchLazyFields(definition, pageData)
      }

      const result = {
        pageData,
        i18nRouteParams
      }

      return result
    } catch (e) {
      throw new Error(`Fetching ${locale}/${slug} failed! - ${e}`)
    }
  }

  /**
   * @function useRelatedPagesAsyncData - Returns the related pages data obtained from the query of a page
   * @param {Object} definition - Related page definition
   * @param {Object} options - Options to modify the data { limit = 1, fields = [] }
   * @returns {Promise} data - With the params pageData and globalContentType if applicable
   */
  const useRelatedPagesAsyncData = async (
    definition,
    {
      limit = 1,
      fields = [],
      skip = 0,
      batchSize = 100
    } = {}
  ) => {
    const locale = getLocaleIso(localeRef.value)

    // Use limit = 0 to fetch all items
    const needsMultipleRetrievals = limit === 0 || limit > batchSize
    const queryLimit = limit === 0 ? batchSize : Math.min(limit, batchSize)

    try {
      const data = await queryContentful({
        query: getQueryForContentType(definition?.contentType, {
          limit: queryLimit,
          fields,
          skip,
          withTotal: true,
          preview,
          layerFragments
        }),
        variables: { locale }
      })

      if (!data || !data.page) {
        return { items: [] }
      }

      // Repeat the retrieval until we fetch ALL items
      if (needsMultipleRetrievals) {
        let itemsRetrieved = data.page.items.length
        while (itemsRetrieved < data.page.total) {
          const subData = await queryContentful({
            query: getQueryForContentType(definition?.contentType, {
              limit: queryLimit,
              fields,
              skip: itemsRetrieved,
              preview,
              layerFragments
            }),
            variables: { locale }
          })
          data.page.items = data.page.items.concat(subData.page.items)
          itemsRetrieved += subData.page.items.length
        }
      }

      const entries = data.page.items?.map(entry => dataDynamicPageMapper(entry, { contentType: definition.contentType }))
        .filter((entry, index, entries) => entries.findIndex(e => e.slug === entry.slug) === index) || []

      return {
        total: entries.length,
        items: entries
      }
    } catch (e) {
      throw new Error(`Fetching related pages for ${locale}/ failed! - ${e}`)
    }
  }

  /**
   * @function useRelatedPagesAsyncDataWithQuery - Returns the related pages data obtained from the query of a page
   * @param {Object} query
   * @param {Object} options - Options to modify the data { limit = 1, fields = [] }
   * @returns {Promise} data - With the params pageData and globalContentType if applicable
   */
  const useRelatedPagesAsyncDataWithQuery = async (
    query,
    {
      limit = 0,
      skipMapping = false,
      dynamic = false,
      // fields = [],
      // skip = 0,
      batchSize = 100
    } = {}
  ) => {
    const locale = getLocaleIso(localeRef.value)

    // Use limit = 0 to fetch all items
    const needsMultipleRetrievals = limit === 0 || limit > batchSize
    // const queryLimit = limit === 0 ? batchSize : Math.min(limit, batchSize)

    try {
      const data = await queryContentful({
        query,
        variables: { locale, preview }
      })

      if (!data || !data.page) {
        return { items: [] }
      }

      // Repeat the retrieval until we fetch ALL items
      if (needsMultipleRetrievals) {
        let itemsRetrieved = data.page.items.length
        while (itemsRetrieved < data.page.total) {
          const subData = await queryContentful({
            query,
            variables: { locale, preview }
          })
          data.page.items = data.page.items.concat(subData.page.items)
          itemsRetrieved += subData.page.items.length
        }
      }

      const entries = skipMapping
        ? data.page.items
        : data.page.items?.map((entry) => {
          return dataPageMapper(entry)
        }) || []

      return {
        total: entries.length,
        items: entries
      }
    } catch (e) {
      throw new Error(`Fetching related pages for ${locale}/ failed! - ${e}`)
    }
  }

  /**
   * @function fetchLazyFields - Fetches the content for all the lazy fields of the definition and mutates the pageData
   * to hydrate them
   * @param {Object} definition - Page definition
   * @param {Object} pageData - Mapped pageData with some lazy fields already mapped
   */

  async function fetchLazyFields (definition, pageData) {
    if (!definition) {
      return
    }
    const locale = getLocaleIso(localeRef.value)

    // Obtain lazy fields (ex: tabContents)
    const lazyFields = definition.fields.filter(f => f.lazy)
    if (lazyFields.length === 0) {
      return
    }

    // Obtain all the entries from those fields that need to be fetched
    const extraEntries = lazyFields.flatMap((field) => {
      if (pageData.content[field.id]) {
        return getLazyFieldMapper(field)(pageData.content[field.id])
      } else {
        return []
      }
    })

    if (extraEntries.length === 0) {
      return
    }

    // Query all the lazy entries
    const data = await queryContentful({
      query: getQueryForEntries(extraEntries, { preview, layerFragments }),
      variables: { locale }
    })
    // Map the newly obtained entries
    const extraDataMap = new Map()
    Object.entries(data).forEach(([key, value]) => {
      const id = key.split('_')[1]
      const entryOptions = extraEntries.find(e => e.id === id)?.imageOptions || {}
      const mappedValue = dataDynamicEntryMapper(value, { withContentType: true, ...entryOptions }, layerMappers)
      extraDataMap.set(id, mappedValue)
    })

    // Replace the data
    lazyFields.forEach((field) => {
      pageData.content[field.id] = replaceLazyFields(field, pageData.content[field.id], extraDataMap)
    })
  }

  return {
    useAsyncDataWithQuery,
    useStandardAsyncData,
    fetchLazyFields,
    useRelatedPagesAsyncData,
    useRelatedPagesAsyncDataWithQuery
  }
}
