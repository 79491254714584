import { gql } from 'graphql-tag'
import fragmentModuleVideo from '../module-video/fragment-module-video'
import fragmentModuleNavigationLink from '../module-navigation-link/fragment-module-navigation-link'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentModuleVideo}
  fragment ModuleMediaCardFragment on ModuleMediaCard {
      sys {
        id
      }
      image {
        src
        color
        frontifyAsset
        source
      }
      video {
        ...ModuleVideoFragment
      }
      icon
      title
      cardDescription: description
      link {
        ...ModuleNavigationLinkFragment
      }
  }
`
