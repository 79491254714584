const BlockKnowledgeHubTopic = {
  contentType: 'BlockKnowledgeHubTopic',
  fields: [{
    id: 'image',
    name: 'Image',
    type: 'ModuleMedia'
  },
  {
    id: 'topic',
    name: 'Topic',
    type: ['GlobalDataTopic', 'GlobalDataArticleType', 'GlobalDataProduct', 'GlobalDataIndustry']
  }]
}

const BlockKnowledgeHubChapter = {
  contentType: 'BlockKnowledgeHubChapter',
  fields: [{
    id: 'media',
    name: 'Media image or video',
    type: ['ModuleMedia', 'ModuleVideo']
  }, {
    id: 'title',
    name: 'Title',
    type: 'Symbol'
  }, {
    id: 'navigationTitle',
    name: 'Navigation title',
    type: 'Symbol'
  }, {
    name: 'Text',
    type: 'RichText'
  }]
}

const PageKnowledgeHubDetail = {
  contentType: 'PageKnowledgeHubDetail',
  fields: [
    {
      id: 'topics',
      name: 'Topics',
      type: 'Array',
      subType: 'GlobalDataTopic',
      validations: [{ size: { min: 1, max: 8 } }]
    },
    {
      id: 'topicsTagged',
      name: 'Topics Tagged',
      type: 'Symbol'
    },
    {
      id: 'articleType',
      name: 'Article Type',
      type: 'GlobalDataArticleType'
    },
    {
      id: 'products',
      name: 'Products',
      type: 'Array',
      subType: 'GlobalDataProduct',
      validations: [{ size: { min: 0, max: 5 } }]
    },
    {
      id: 'productsTagged',
      name: 'Products Tagged',
      type: 'Symbol'
    },
    {
      id: 'industries',
      name: 'Industries',
      type: 'Array',
      subType: 'GlobalDataIndustry',
      validations: [{ size: { min: 0, max: 5 } }]
    },
    {
      id: 'industriesTagged',
      name: 'Industries Tagged',
      type: 'Symbol'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia'
    },
    {
      id: 'displayFullWidthImageHero',
      name: 'Display Full Width Image Hero',
      type: 'Boolean'
    },
    {
      id: 'shortDescription',
      name: 'Short Description',
      type: 'Text'
    },
    {
      id: 'date',
      name: 'Date',
      type: 'Date'
    },
    {
      id: 'minutesToRead',
      name: 'Minutes to Read',
      type: 'Integer'
    },
    {
      id: 'author',
      name: 'Author',
      type: 'GlobalDataArticleAuthor'
    },
    {
      id: 'legacyContent',
      name: 'Legacy HTML Content',
      type: 'Text'
    },
    {
      id: 'intro',
      name: 'Intro Text',
      type: 'RichText'
    },
    {
      id: 'related',
      name: 'Related Knowledge Hub Articles',
      type: 'Array',
      subType: 'PageKnowledgeHubDetail',
      validations: [{ size: { min: 0, max: 3 } }]
    },
    {
      id: 'chapters',
      name: 'Chapters',
      type: 'Array',
      subType: 'BlockKnowledgeHubChapter',
      validations: [{ size: { min: 1, max: 10 } }],
      required: false // because we can have the "legacyContent" HTML instead for now
    },
    {
      id: 'gatedContentEnabled',
      name: 'Gated Content Enabled',
      type: 'Boolean'
    },
    {
      id: 'gatedContentFormId',
      name: 'Gated Content Form ID',
      type: 'Symbol'
    },
    {
      id: 'gatedContentFormTitle',
      name: 'Gated Content Form Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentSuccessTitle',
      name: 'Gated Content Success Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentSuccessText',
      name: 'Gated Content Success Text',
      type: 'Text'
    },
    {
      id: 'gatedContentSuccessDownload',
      name: 'Gated Content Success Download',
      type: ['ModuleNavigationLink', 'ModuleMedia']
    }
  ]
}

const PageKnowledgeHub = {
  contentType: 'PageKnowledgeHub',
  fields: [
    {
      id: 'highlightedTopArticles',
      name: 'Highlighted Top Articles',
      type: 'Array',
      subType: 'PageKnowledgeHubDetail',
      validations: [{ size: { min: 5, max: 5 } }]
    },
    {
      id: 'fixedArticlesTitle',
      name: 'Fixed Articles Title',
      type: 'Symbol'
    },
    {
      id: 'fixedArticlesArticleType',
      name: 'Fixed Articles Article Type',
      type: 'GlobalDataArticleType'
    },
    {
      id: 'highlightBlock',
      name: 'Highlight Block',
      type: 'PageKnowledgeHubDetail'
    },
    {
      id: 'latestPostsTitle',
      name: 'Latest Posts Title',
      type: 'Symbol'
    },
    {
      id: 'popularTopicsTitle',
      name: 'Popular Topics Title',
      type: 'Symbol'
    },
    {
      id: 'popularTopics',
      name: 'Popular Topics',
      type: 'Array',
      subType: 'BlockKnowledgeHubTopic',
      validations: [{ size: { min: 1, max: 4 } }]
    },
    {
      id: 'customRelatedArticlesTitle',
      name: 'Custom Related Articles Title',
      type: 'Symbol'
    },
    {
      id: 'customRelatedArticlesTopic',
      name: 'Custom Related Articles Topic',
      type: ['GlobalDataTopic', 'GlobalDataArticleType', 'GlobalDataProduct', 'GlobalDataIndustry']
    }
  ]
}

const KnowledgeHubLabels = {
  contentType: 'knowledgeHubLabels',
  fields: [
    {
      id: 'backLink',
      name: 'Back Link Label',
      type: 'Symbol'
    },
    {
      id: 'articleTypeFilter',
      name: 'Article Type Filter',
      type: 'Symbol'
    },
    {
      id: 'topicFilter',
      name: 'Topic Filter',
      type: 'Symbol'
    },
    {
      id: 'industryFilter',
      name: 'Industry Filter',
      type: 'Symbol'
    },
    {
      id: 'sidebarTopics',
      name: 'Topics for Sidebar',
      type: 'Symbol'
    },
    {
      id: 'sidebarShare',
      name: 'Share for Sidebar',
      type: 'Symbol'
    },
    {
      id: 'relatedArticles',
      name: 'Related Articles Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentDownloadButton',
      name: 'Gated Content Download Button',
      type: 'Symbol'
    }
  ]
}

const PageKnowledgeHubArticles = {
  contentType: 'PageKnowledgeHubArticles',
  fields: [
    {
      id: 'noResultsTitle',
      name: 'No Results Title',
      type: 'Symbol'
    },
    {
      id: 'noResultsSubtitle',
      name: 'No Results Subtitle',
      type: 'Symbol'
    }
  ]
}

export default {
  BlockKnowledgeHubChapter,
  PageKnowledgeHubDetail,
  BlockKnowledgeHubTopic,
  PageKnowledgeHub,
  KnowledgeHubLabels,
  PageKnowledgeHubArticles
}
