import imgModule from '../../constants/imageSizes.js'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const PartnerType = Object.freeze({
  ALLIANCE: 'ALLIANCE',
  TECH: 'Tech'
})

const PagePartners = {
  contentType: 'PagePartners',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns5_34), lazy: false }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: [
          { width: 327, height: 245, aspectRatio: '4/3' },
          { width: 312, height: 416, aspectRatio: '3/4' },
          { width: 576, height: 468, aspectRatio: '4/3' }

        ],
        lazy: true
      }

    },
    // {
    //   id: 'quote',
    //   name: 'Quote',
    //   type: 'BlockQuote'
    // },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 436, aspectRatio: '3/4' },
          medium: { width: 312, height: 416, aspectRatio: '3/4' },
          large: { width: 368, height: 491, aspectRatio: '3/4' }
        })
      }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PagePartnersMerchant = {
  contentType: 'PagePartnersMerchant',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 436, aspectRatio: '3/4' },
          medium: { width: 312, height: 416, aspectRatio: '3/4' },
          large: { width: 368, height: 491, aspectRatio: '3/4' }
        }),
        lazy: true
      }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 245, aspectRatio: '4/3' },
          medium: { width: 312, height: 416, aspectRatio: '3/4' },
          large: { width: 576, height: 432, aspectRatio: '4/3' }
        }),
        lazy: true
      }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'media',
      name: 'Media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns5_34), lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PagePartnersOverview = {
  contentType: 'PagePartnersOverview',
  fields: [
    {
      id: 'pageTitle',
      name: 'Page title',
      type: 'Symbol'
    },
    {
      id: 'pageSubtitle',
      name: 'Page subtitle',
      type: 'Symbol'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const BlockPartnerMetadata = {
  contentType: 'BlockPartnerMetadata',
  fields: [
    {
      id: 'partnerName',
      name: 'Partner name',
      type: 'Symbol'
    },
    {
      id: 'location',
      name: 'Location',
      type: 'GlobalDataCountry'
    },
    {
      id: 'languages',
      name: 'Languages',
      type: 'Array',
      subType: 'GlobalDataLanguage'
    },
    {
      id: 'url',
      name: 'Url',
      type: 'Symbol'
    },
    {
      id: 'availability',
      name: 'Availability',
      type: 'Array',
      subType: 'GlobalDataCountry'
    },
    {
      id: 'industries',
      name: 'Industries',
      type: 'Array',
      subType: 'GlobalDataIndustry'
    },
    {
      id: 'products',
      name: 'Products',
      type: 'Array',
      subType: 'GlobalDataProduct'
    },
    {
      id: 'partners',
      name: 'partners',
      type: 'Array',
      subType: 'PagePartnersDetail'
    },
    {
      id: 'paymentMethods',
      name: 'PaymentMethods',
      type: 'Array',
      subType: 'PagePaymentMethodsDetail'
    },
    {
      id: 'terminals',
      name: 'Terminals',
      type: 'Array',
      subType: 'PageTerminalsDetail'
    }
  ]
}

const PagePartnersDetail = {
  contentType: 'PagePartnersDetail',
  fields: [
    {
      id: 'partnerName',
      name: 'Partner name',
      type: 'Symbol',
      includeInParent: true
    },
    {
      id: 'partnerDescription',
      name: 'Partner description',
      type: 'Text',
      includeInParent: true
    },
    {
      id: 'partnerLogo',
      name: 'Partner logo',
      type: 'ModuleMedia',
      includeInParent: true
    },
    {
      id: 'partnerType',
      name: 'Partner type',
      type: 'Symbol',
      localized: false,
      includeInParent: true,
      validations: [
        { in: Object.values(PartnerType) }
      ]
    },
    {
      id: 'partnerMetadata',
      name: 'Partner metadata',
      lazy: true,
      includeInParent: true,
      type: 'BlockPartnerMetadataLazy'
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: 'ModuleMedia',
      imageOptions: {
        resize: true,
        sizes: Object.values(
          {
            small: { width: 327, height: 184, aspectRatio: '16/9' },
            medium: { width: 672, height: 378, aspectRatio: '16/9' },
            large: { width: 680, height: 382, aspectRatio: '16/9' }
          }
        ),
        lazy: true
      }
    },
    {
      id: 'aboutTitle',
      name: 'About title',
      type: 'Symbol'
    },
    {
      id: 'aboutDescription',
      name: 'About description',
      type: 'RichText'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote',
      imageOptions: {
        resize: true,
        sizes: Object.values({
          small: { width: 327, height: 245, aspectRatio: '4/3' },
          medium: { width: 312, height: 416, aspectRatio: '3/4' },
          large: { width: 312, height: 416, aspectRatio: '3/4' }
        }),
        lazy: true
      }
    },

    {
      id: 'contentBlockServices',
      name: 'Content block services',
      type: 'BlockGroup'
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'mediaVideo',
      name: 'Media video',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52), lazy: true }
    },
    {
      id: 'caseStudy',
      name: 'Case study',
      type: 'BlockQuote',
      imageOptions: {
        resize: true,
        sizes: Object.values(
          {
            small: { width: 327, height: 184, aspectRatio: '16/9' },
            medium: { width: 400, height: 400, aspectRatio: '1/1' },
            large: { width: 400, height: 400, aspectRatio: '1/1' }
          }
        ),
        lazy: true
      }
    },
    {
      id: 'contentBlockSteps',
      name: 'Content block steps',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock',
      name: 'Image + text block',
      type: 'BlockContent'
    },
    {
      id: 'documentation',
      name: 'Documentation',
      type: 'BlockGroup'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PagePartnersDetailContent = {
  contentType: 'PagePartnersDetailContent',
  isContent: true,
  fields: [
    {
      id: 'partnerName',
      name: 'Partner name',
      type: 'Symbol',
      includeInParent: true
    },
    {
      id: 'partnerDescription',
      name: 'Partner description',
      type: 'Text',
      includeInParent: true
    },
    {
      id: 'partnerLogo',
      name: 'Partner logo',
      type: 'ModuleMedia',
      includeInParent: true
    },
    {
      id: 'partnerType',
      name: 'Partner type',
      type: 'Symbol',
      localized: false,
      includeInParent: true,
      validations: [
        { in: Object.values(PartnerType) }
      ]
    },
    {
      id: 'partnerMetadata',
      name: 'Partner metadata',
      includeInParent: true,
      type: 'BlockPartnerMetadata'
    }
  ]
}

const PartnersLabels = {
  contentType: 'PartnersLabels',
  fields: [
    {
      id: 'industry',
      name: 'Industry',
      type: 'Symbol'
    },
    {
      id: 'region',
      name: 'Region',
      type: 'Symbol'
    },
    {
      id: 'product',
      name: 'Product',
      type: 'Symbol'
    },
    {
      id: 'language',
      name: 'Language',
      type: 'Symbol'
    },
    {
      id: 'partnersFound',
      name: 'Partners found',
      type: 'Symbol'
    },
    {
      id: 'all',
      name: 'All',
      type: 'Symbol'
    },
    {
      id: 'alliancePartner',
      name: 'Alliance partner',
      type: 'Symbol'
    },
    {
      id: 'alliancePartners',
      name: 'Alliance partners',
      type: 'Symbol'
    },
    {
      id: 'techPartner',
      name: 'Tech partner',
      type: 'Symbol'
    },
    {
      id: 'techPartners',
      name: 'Tech partners',
      type: 'Symbol'
    },
    {
      id: 'showAllPartners',
      name: 'Show all partners',
      type: 'Symbol'
    },
    {
      id: 'sorryMessage',
      name: 'Sorry message',
      type: 'Symbol'
    },
    {
      id: 'showAll',
      name: 'Show all',
      type: 'Symbol'
    },
    {
      id: 'availableIn',
      name: 'Available in',
      type: 'Symbol'
    },
    {
      id: 'terminalsSupported',
      name: 'Terminals supported',
      type: 'Symbol'
    },
    {
      id: 'paymentMethods',
      name: 'Payment methods',
      type: 'Symbol'
    },
    {
      id: 'adyenProduct',
      name: 'Adyen product',
      type: 'Symbol'
    }

  ]
}

export default {
  PagePartners,
  PagePartnersMerchant,
  PagePartnersOverview,
  PartnersLabels,
  PagePartnersDetail,
  PagePartnersDetailContent,
  BlockPartnerMetadata,
  PartnerType
}
