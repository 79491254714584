import imgModule from '../../constants/imageSizes.js'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const heroImageSizes = Object.values(imageSizes.columns5_34)
const heroVerticalImageSizes = Object.values(imageSizes.columns12_5269)

const PageIndustryMobilityPayments = {
  contentType: 'PageIndustryMobilityPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'quoteVideo',
      name: 'quoteMedia',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'contentBlock1Extra',
      name: 'Content block 1 extra',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'callToAction1',
      name: 'Call to action 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'experiencesUsps',
      name: 'Experiences usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent'
    },
    {
      id: 'callToAction2',
      name: 'Call to action 2',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryRetailPayments = {
  contentType: 'PageIndustryRetailPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustrySubscriptionPayments = {
  contentType: 'PageIndustrySubscriptionPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), injectContentType: true, lazy: true }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'recoverUsps',
      name: 'Recover usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryHotelPayments = {
  contentType: 'PageIndustryHotelPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true, injectContentType: true }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryDigitalPayments = {
  contentType: 'PageIndustryDigitalPayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryFoodBeveragePayments = {
  contentType: 'PageIndustryFoodBeveragePayments',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: 'ModuleVideo',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269) }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'contentBlockBanner',
      name: 'Content block banner',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'benefits2',
      name: 'Benefits 2',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryOverview = {
  contentType: 'PageIndustryOverview',
  fields: []
}

export default {
  PageIndustryMobilityPayments,
  PageIndustryRetailPayments,
  PageIndustrySubscriptionPayments,
  PageIndustryFoodBeveragePayments,
  PageIndustryHotelPayments,
  PageIndustryDigitalPayments,
  PageIndustryOverview
}
