import { mapperIcon } from '../../utils/mappers'
import { mapperModuleMedia, mapperModuleNavigationLink } from '../../modules/mappers'
import type { MapperOptions } from '../../../types'

/**
 * BlockCallToAction
 */
export const mapperBlockCallToActionWithMedia = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  const image = mapperModuleMedia(data.image, options.imageOptions)
  return {
    icon: mapperIcon(data.icon),
    title: data.title || '',
    text: data.text || '',
    image,
    link: mapperModuleNavigationLink(data.link, options.getUrl)
  }
}
