import { gql } from 'graphql-tag'
import fragmentModuleMedia from '../../modules/module-media/fragment-module-media'
import fragmentModuleNavigationLink from '../../modules/module-navigation-link/fragment-module-navigation-link'
import fragmentModuleVideo from '../../modules/module-video/fragment-module-video'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleNavigationLink}
  fragment BlockContentFragment on BlockContent {
    sys {
      id
    }
    title
    contentDescription: description {
      json
    }
    icon
    eyebrow
    linksCollection {
      items {
        ...ModuleNavigationLinkFragment
      }
    }
    image {
      ...ModuleMediaFragment
    }
    video {
      ...ModuleVideoFragment
    }
  }
`
