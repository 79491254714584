import { print } from 'graphql'
export const KNOWN_ERROR_STATUS_CODES = [429]

export const useQueries = () => {
  const DEFAULT_RETRIES = 3
  const DEFAULT_RETRY_TIME = 250

  const _queryContentful = async ({ query, variables }: {query: any, variables: any}, options: { log?: boolean}) => {
    if (options?.log) {
      // eslint-disable-next-line no-console
      console.log(print(query))
      // eslint-disable-next-line no-console
      console.log(variables)
    }

    try {
      const data = await $fetch('/api/contentful', {
        method: 'POST',
        body: {
          // query: minifyGraphQLQuery(query),
          query,
          variables
        }
      })

      return data
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.error(e)
      console.log('Error for', variables?.slug)
      return { error: e }
    }
  }

  const queryContentful = async (queryAndVars: { query: any, variables: any }, options: { log?: boolean, retries?: number, retryTime?: number }) => {
    const retries = options?.retries || DEFAULT_RETRIES
    const retryTime = options?.retryTime || DEFAULT_RETRY_TIME
    const log = true

    let remainingAttempts = retries
    let result = await _queryContentful(queryAndVars, options)
    let backoffFactor = 0
    const slugData = queryAndVars?.variables?.slug ? `for [${queryAndVars.variables.slug}]` : '(no slug)'
    const hasVariables = queryAndVars?.variables && Object.keys(queryAndVars?.variables).length > 0

    while ((!result || result?.error) && remainingAttempts > 0) {
      const waitTime = retryTime * Math.pow(2, backoffFactor)

      // eslint-disable-next-line no-console
      console.warn(`[Contentful Query] to ${slugData} failed! - ${remainingAttempts} attempts remaining. Retrying after ${waitTime} ms.`)
      await new Promise(resolve => setTimeout(resolve, waitTime))
      result = await _queryContentful(queryAndVars, { log })
      remainingAttempts--
      backoffFactor++
    }
    if (result && !result.error) {
      return result
    } else {
      const errorMessage = `[Contentful Query] ${slugData} failed after ${retries + 1} attempts.`
      // eslint-disable-next-line no-console
      console.error(errorMessage)
      // Parsing error object to get the value of `e.status`
      // For some reason `e.status` is undefined
      const e = result.error
      const safeError = JSON.parse(JSON.stringify(e))
      // eslint-disable-next-line no-console
      console.error(`[Query] error catched. Status ${e.status}, Safe Status ${safeError.status}, code: ${e.code}, message: ${e.message}, variables: ${hasVariables ? JSON.stringify(queryAndVars.variables) : ''}`)
      if (!KNOWN_ERROR_STATUS_CODES.includes(safeError.status)) {
        if (safeError.config?.data) {
          safeError.config.data = JSON.parse(safeError.config.data)
          safeError.config.data.query = '...'
        }
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(safeError, null, 2))
        const printedQuery = print(queryAndVars?.query) as string
        console.error('\n' + printedQuery.replaceAll('\n', '').replaceAll(/\s+/g, ' '))
      }
      throw new Error(errorMessage)
    }
  }

  function minifyGraphQLQuery (query: string) {
    return query
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .replace(/}\s+/g, '}') // Remove space after closing braces
      .replace(/\s+{/g, '{') // Remove space before opening braces
      .trim() // Remove leading and trailing spaces
  }

  return {
    queryContentful
  }
}
