import { gql } from 'graphql-tag'
// eslint-disable-next-line import/extensions
import fragmentModuleVideo from '../module-video/fragment-module-video'

export default gql`
  ${fragmentModuleVideo}
  fragment ModuleCompanyFormulaItemFragment on ModuleCompanyFormulaItem {
    sys {
      id
    }
    name
    icon
    shortDescription {
      json
    }
    description {
      json
    }
    video {
      ...ModuleVideoFragment
    }
  }
`
