import { gql } from 'graphql-tag'
import fragmentModuleMedia from '../../modules/module-media/fragment-module-media'
import { fragmentGlobalDataTheme } from '../../global-data/fragments'
import fragmentModuleNavigationLink from '../../modules/module-navigation-link/fragment-module-navigation-link'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleNavigationLink}
  ${fragmentGlobalDataTheme}
  fragment BlockLandingLogoBarFragment on BlockLandingLogoBar {
    sys {
      id
    }
    logosCollection (limit: 12) {
      items {
        ...ModuleMediaFragment
      }
    }
    title
    logosDescription: description {
      json
    }
    link {
      ...ModuleNavigationLinkFragment
    }
    footnote
    theme {
      ...GlobalDataThemeFragment
    }
  }
`
