import { mapperModuleMedia, mapperModuleNavigationLink } from '../../modules/mappers'
import { mapperGlobalDataTheme } from '../../global-data/mappers'
import type { MapperOptions, ModuleMedia } from '../../../types'

/**
 * BlockLogoBar
 */
export const mapperBlockLandingLogoBar = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  const { imageOptions, getUrl } = options

  return {
    id: data.sys?.id || data.id,
    items: data.logosCollection?.items?.map(
      (item: any) => {
        const image = mapperModuleMedia(item, { ...imageOptions, lazy: true }) as ModuleMedia
        return { ...image.small, isFrontifyAsset: image.original.isFrontifyAsset }
      }) || [],
    footnote: data.footnote || '',
    title: data.title || '',
    description: data.logosDescription || null,
    link: mapperModuleNavigationLink(data.link, getUrl),
    theme: mapperGlobalDataTheme(data.theme)?.theme || null
  }
}
