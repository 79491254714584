export const CareerType = Object.freeze({
  TECH: {
    id: 'TECH',
    logo: 'team--development-team'
  },
  COMMERCIAL: {
    id: 'COMMERCIAL',
    logo: 'team--marketing-team'
  },
  PRODUCT: {
    id: 'PRODUCT',
    logo: 'team--product-design-team'
  },
  OPERATIONAL: {
    id: 'OPERATIONAL',
    logo: 'team--merchant-operations-team'
  },
  NEXTGEN: {
    id: 'NEXTGEN',
    logo: 'team--team'
  }
})

/* TODO: Missing logos
* - Austin
* - Cincinnati
* - Mumbai
* - Prague
* - Remote
* - Singapore
* - Toronto
* - Warsow
 */

export const CareerOffice = Object.freeze({
  AMSTERDAM: {
    id: 5141,
    name: 'Amsterdam',
    logo: 'cities--amsterdam'
  },
  AUSTIN: {
    id: 44560,
    name: 'Austin',
    logo: 'cities--amsterdam'
  },
  BENGALURU: {
    id: 109483,
    name: 'Bengaluru',
    logo: 'cities--amsterdam'
  },
  BERLIN: {
    id: 5144,
    name: 'Berlin',
    logo: 'cities--berlin'
  },
  BOSTON: {
    id: 67346,
    name: 'Boston',
    logo: 'cities--boston'
  },
  BRUSSELS: {
    id: 25814,
    name: 'Brussels',
    logo: 'cities--brussels'
  },
  CHICAGO: {
    id: 44559,
    name: 'Chicago',
    logo: 'cities--chicago'
  },
  CINCINNATI: {
    id: 63373,
    name: 'Cincinnati',
    logo: 'cities--amsterdam'
  },
  DUBAI: {
    id: 76517,
    name: 'Dubai',
    logo: 'cities--dubai'
  },
  HONGKONG: {
    id: 61033,
    name: 'Hong Kong',
    logo: 'cities--hong-kong-2'
  },
  KUALALUMPUR: {
    id: 82436,
    name: 'Kuala Lumpur',
    logo: 'cities--kuala-lumpur'
  },
  LONDON: {
    id: 5142,
    name: 'London ',
    logo: 'cities--london'
  },
  MADRID: {
    id: 5152,
    name: 'Madrid ',
    logo: 'cities--madrid'
  },
  MANCHESTER: {
    id: 51985,
    name: 'Manchester',
    logo: 'cities--manchester'
  },
  MELBOURNE: {
    id: 25815,
    name: 'Melbourne',
    logo: 'cities--melbourne'
  },
  MEXICOCITY: {
    id: 21542,
    name: 'Mexico City',
    logo: 'cities--mexico-city'
  },
  MILAN: {
    id: 23318,
    name: 'Milan',
    logo: 'cities--milan'
  },
  MUMBAI: {
    id: 61105,
    name: 'Mumbai',
    logo: 'cities--amsterdam'
  },
  MUNICH: {
    id: 66853,
    name: 'Munich',
    logo: 'cities--munich'
  },
  NEWYORK: {
    id: 5146,
    name: 'New York',
    logo: 'cities--new-york'
  },
  PARIS: {
    id: 5143,
    name: 'Paris',
    logo: 'cities--paris'
  },
  PRAGUE: {
    id: 87129,
    name: 'Prague',
    logo: 'cities--amsterdam'
  },
  REMOTEUSA: {
    id: 63854,
    name: 'Remote, USA',
    logo: 'cities--amsterdam'
  },
  SANFRANCISCO: {
    id: 5145,
    name: 'San Francisco',
    logo: 'cities--san-francisco'
  },
  SAOJOSEDOSCAMPOS: {
    id: 82308,
    name: 'São José dos Campos',
    logo: 'cities--sao-jose-dos-campos'
  },
  SAOPAULO: {
    id: 5148,
    name: 'São Paulo',
    logo: 'cities--sao-paulo'
  },
  SHANGHAI: {
    id: 24867,
    name: 'Shanghai',
    logo: 'cities--shangai'
  },
  SINGAPORE: {
    id: 5147,
    name: 'Singapore',
    logo: 'cities--amsterdam'
  },
  STOCKHOLM: {
    id: 5149,
    name: 'Stockholm',
    logo: 'cities--stockholm'
  },
  SYDNEY: {
    id: 5444,
    name: 'Sydney',
    logo: 'cities--sidney'
  },
  TOKYO: {
    id: 60802,
    name: 'Tokyo',
    logo: 'cities--tokyo'
  },
  TORONTO: {
    id: 62321,
    name: 'Toronto',
    logo: 'cities--amsterdam'
  },
  WARSAW: {
    id: 67973,
    name: 'Warsaw',
    logo: 'cities--amsterdam'
  },
  NOT_SET: {
    id: -1,
    name: '-',
    logo: 'cities--amsterdam'
  }
})

export const CareerTeam = Object.freeze({
  ACCOUNT_MANAGEMENT: {
    id: 8348,
    name: 'Account Management',
    logo: 'team--account-management'
  },
  COMMERCIAL: {
    id: 78317,
    name: 'Commercial',
    logo: 'team--commercial-operations-team'
  },
  COMMERCIAL_OPERATIONS: {
    id: 80272,
    name: 'Commercial Operations',
    logo: 'team--commercial-operations-team'
  },
  COMMUNICATIONS: {
    id: 65358,
    name: 'Communications',
    logo: 'team--communications-team'
  },
  COMPLIANCE: {
    id: 8354,
    name: 'Compliance',
    logo: 'team--compliance-team'
  },
  CORPORATE_RISK: {
    id: 33157,
    name: 'Corporate Risk',
    logo: 'team--risk-team'
  },
  DATA_ANALYTICS: {
    id: 78319,
    name: 'Data Analytics',
    logo: 'team--data-analytics-team'
  },
  DEVELOPMENT: {
    id: 8362,
    name: 'Development',
    logo: 'team--development-team'
  },
  FINANCE: {
    id: 8350,
    name: 'Finance',
    logo: 'team--finance-team'
  },
  HUMAN_RESOURCES: {
    id: 8346,
    name: 'Human Resources',
    logo: 'team--human-resources-team'
  },
  INFRASTRUCTURE: {
    id: 8360,
    name: 'Infrastructure',
    logo: 'team--infrastructure-team'
  },
  INTERNAL_AUDIT: {
    id: 37958,
    name: 'Internal Audit',
    logo: 'team--internal-audit-team'
  },
  INTERNSHIP: {
    id: 78325,
    name: 'Internship',
    logo: 'team--team'
  },
  LEGAL: {
    id: 8412,
    name: 'Legal',
    logo: 'team--legal-team'
  },
  MARKETING: {
    id: 8351,
    name: 'Marketing',
    logo: 'team--marketing-team'
  },
  MERCHANT_OPERATIONS: {
    id: 30437,
    name: 'Merchant Operations',
    logo: 'team--merchant-operations-team'
  },
  NEXTGEN: {
    id: 78324,
    name: 'NextGen',
    logo: 'team--team'
  },
  PARTNERSHIPS: {
    id: 8358,
    name: 'Partnerships',
    logo: 'team--partnerships-team'
  },
  PAYMENT_PARTNERSHIPS: {
    id: 78323,
    name: 'Payments Partnerships',
    logo: 'team--payment-partnerships-team'
  },
  PHD: {
    id: 56891,
    name: 'PhD program',
    logo: 'team--team'
  },
  PRODUCT_DESIGN: {
    id: 78320,
    name: 'Product Design',
    logo: 'team--product-design-team'
  },
  PRODUCT_MANAGEMENT: {
    id: 39124,
    name: 'Product Management',
    logo: 'team--product-management-team'
  },
  RISK: {
    id: 31535,
    name: 'Risk',
    logo: 'team--risk-team'
  },
  SALES: {
    id: 8347,
    name: 'Sales',
    logo: 'team--sales-team'
  },
  SECURITY: {
    id: 8353,
    name: 'Security',
    logo: 'team--security-team'
  },
  SUPPORT_SERVICES: {
    id: 78321,
    name: 'Support Services',
    logo: 'team--support-and-services-team'
  },
  TREASURY: {
    id: 78322,
    name: 'Treasury',
    logo: 'team--team'
  },
  WORKING_STUDENTS: {
    id: 50048,
    name: 'Working Students',
    logo: 'team--team'
  },
  STUDENTS_GRADUATES: {
    id: 78318,
    name: 'Students & Graduates',
    logo: 'team--team'
  },
  NOT_SET: {
    id: -1,
    name: '-',
    logo: 'team--team'
  }
})
